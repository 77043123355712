import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/tManager',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/tManager/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'crm/tManager',
    method: 'put',
    data
  })
}


export function saveManager(data) {
  return request({
    url: 'crm/srPermissionDimensionUser/saveManager',
    method: 'GET',
    params: data,
  })
}
//后台查询经济人的代理链接 params: managerId
export function queryManagerLink(data) {
  return request({
    url: 'crm/tManagerLink',
    method: 'GET',
    params: data,
  })
}
// levelId
export function queryLevleAndRules(data) {
  return request({
    url: '/crm/levleAndRules',
    method: 'GET',
    params: data,
  })
}
//custId 后台查询子代理级别及返佣规则
export function querySubLevleAndRules(data) {
  return request({
    url: '/crm/childLevleAndRules',
    method: 'GET',
    params: data,
  })
}
//后台查询代理级别列表 custId
export function queryMerchantCommissionLevel(data) {
  return request({
    url: '/crm/tMerchantCommissionLevel',
    method: 'GET',
    params: data,
  })
}
// 系统级别

//
export function querySystemLevel(data) {
  return request({
    url: '/crm/querySystemLevel',
    method: 'GET',
  })
}


//  子代理列表
export function querySubAgentList(data) {
  return request({
    url: '/crm/getLevelLinkByCustId',
    method: 'GET',
    params: data,
  })
}

// 统计分析-销售报表/代理报表 合计
export function queryReportTotal(data) {
  return request({
    url: '/crm/managerAchievementSum',
    method: 'GET',
    params: data,
  })
}

export function queryDeptList(data) {
  return request({
    url: '/crm/tDepartment',
    method: 'GET',
    params: data,
  })
}

export function addPartment(data) {
  return request({
    url: '/crm/tDepartment',
    method: 'post',
    data,
  })
}

export function editPartment(data) {
  return request({
    url: '/crm/tDepartment',
    method: 'put',
    data,
  })
}

export function queryTeamInfo(data){
  return request({
    url: '/crm/allCustTeam',
    method: 'get',
    params: data,
  })
}
